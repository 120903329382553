
























import { computed, defineComponent, ref, useFetch, PropType } from '@nuxtjs/composition-api';
import { useProduct } from '~/modules/catalog/product/composables/useProduct';
import { Product } from '~/modules/catalog/product/types';
import { ProductInterface } from '~/modules/GraphQL/types';
import CtaPartial from '@theme/components/molecules/CtaPartial.vue';

export default defineComponent({
  name: 'ProductLister',
  components: {
    CategoryProductGrid: () => import('@theme/modules/catalog/category/components/views/CategoryProductGrid.vue'),
    CtaPartial
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    productSkus: {
      type: Array as PropType<Product[]>,
      default: () => []
    },
    cta: {
      type: Object,
      default: () => ({})
    },
  },
  setup(props) {
    const { getProductDetails } = useProduct();
    const skuArray = ref<string[]>([]);
    const products = ref<ProductInterface[]>([]);

    const sortProducts = (a: ProductInterface, b: ProductInterface) => {
      return skuArray.value.indexOf(a.sku) - skuArray.value.indexOf(b.sku)
    }

    useFetch(async () => {
      skuArray.value = props.productSkus.map(product => product.sku).map(sku => sku.toString());

      const result = await getProductDetails({
        filter: {
          sku: {
            in: skuArray.value
          },
        },
        pageSize: 12
      });

      products.value = (result?.items || []).sort(sortProducts) as ProductInterface[];
    });

    const ctaParams = computed(() => {
      return {
        linkType: props.cta.linkType,
        title: props.cta.title || '',
        url: props.cta.url || '',
        type: props.cta.type || 'Primary',
        newWindow: !!(props.cta.newWindow),
        noFollow: !!(props.cta.noFollow),
        trackingQuery: props.cta.trackingQuery || '',
      }
    });

    return {
      products,
      ctaParams,
    }
  }
});
